import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetricsWidgetSharedModule } from './extensions/a2d34dd5b13a683f23f9e1833ec73294c9b687d6d233dade0a54a6e15ae9938c/metrics-widget.shared-module';
import { StockWidgetSharedModule } from './extensions/bd76f3956ff148602713a8739f5636a893002a491424dccb00f2627f66960439/stock-widget.shared-module';
import { OrderExportNavModule } from './extensions/f4db090cd5ab9835e69bc1f6899d6e9a0f64bca36bcbafde38ebc3d21d5ac704/order-export-nav.module';
import { InvoiceButtonsModule } from './extensions/ba200e114f9714ee6c1a826e6d984838f6d2e636b7d060b9ed74df179850e9fb/invoice-buttons.module';
import { LabelButtonModule } from './extensions/6a09b6e2fdba6cd48302a59f7560a5efce747cefa5e3570bc975f57935a21574/label-button.module';
import { InvoicesNavModule } from './extensions/937b81ae3b7375d9754b0feeeb577ba193920b6d3b65663e44844f452f1c4146/invoices-nav.module';


@NgModule({
    imports: [CommonModule, MetricsWidgetSharedModule, StockWidgetSharedModule, OrderExportNavModule, InvoiceButtonsModule, LabelButtonModule, InvoicesNavModule],
})
export class SharedExtensionsModule {}
