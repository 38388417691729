import { NgModule } from '@angular/core';
import { SharedModule, addActionBarItem, OnClickContext } from '@vendure/admin-ui/core';


const isLocalhost = (hostname: string) => {
    if (hostname == "localhost") {
        return true;
    }

    return false;
}
@NgModule({
    imports: [
        SharedModule
    ],
    providers: [
        addActionBarItem({
            id: "label-button",
            label: "Print label",
            locationId: "order-detail",
            buttonStyle: "outline",
            onClick: (event: MouseEvent, context: OnClickContext) => {

                const view = event.view;
                const id = context.route.snapshot.params.id;
                if (id && view) {

                    const isLocal = isLocalhost(view.window.location.hostname);
                    // TODO: should eventually be agnostic & moved to backend
                    const apiRoute = isLocal ? `${view.window.location.origin}/shipping-label/${id}` : `https://srv.keeb.supply/shipping-label/${id}`;

                    let token = view.window.localStorage.getItem("vnd_authToken");

                    if (token) {
                        token = token.replace(/\"/g, "");
                        fetch(apiRoute, {
                            method: "GET",
                            headers: new Headers(
                                {
                                    Authorization: `Bearer ${token}`
                                }
                            )
                        }).then((resp) => {
                            resp.text().then(text => {

                                if (resp.status != 200) {
                                    alert(`An error occured: ${text}`);
                                    return;
                                }

                                const newView = view.window.open(text);
                                if (newView) {
                                    newView.focus();

                                    // doesn't seem to be allowed :(
                                    newView.addEventListener("load", () => {
                                        newView.print();
                                    });
                                }
                            });
                        }).catch((error) => {
                            alert(`An error occured: ${error}`);
                        });
                    }
                }
            },
            requiresPermission: "SuperAdmin"
        })
    ]
})

// @ts-ignore
export class LabelButtonModule { }
